import React, { useCallback, useContext, useMemo } from 'react';
import style from './overviewTile.module.scss';
import {
  PButtonPure,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { useNavigate } from 'react-router-dom';
import { messages } from './overviewTile.messages';
import { PremiumOnly } from '../../../../components/context/plan/discriminators/tariff/PremiumOnly';
import { FormattedMessage } from 'react-intl';
import { BasicOnly } from '../../../../components/context/plan/discriminators/tariff/BasicOnly';
import { LegacyOnly } from '../../../../components/context/plan/discriminators/tariff/LegacyOnly';
import { observer } from 'mobx-react-lite';
import { subscriptionContext } from '../../../../components/context/subscription/SubscriptionContext';
import { PlanVariant } from 'hooks/usePlans';
import { PriceChangeTag } from '../priceChangeTag/PriceChangeTag';

type OverviewTileProps = { isDisabled?: boolean };

export const OverviewTile: React.FunctionComponent<OverviewTileProps> =
  observer(({ isDisabled = false }) => {
    const context = useContext(subscriptionContext);
    const navigate = useNavigate();

    const tag = useMemo(() => {
      if (context.subscription?.isTerminated && context.subscription.endDate) {
        const endDate = new Date(context.subscription.endDate);
        // Now + 30 days
        const future = new Date(
          new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
        );

        // If end-date is more than 30 days away, do not display the tag
        if (endDate > future) {
          return null;
        }

        if (
          new Date() < endDate &&
          context?.subscription?.currentOrLastPhase?.plan?.variant !==
            PlanVariant.V1_PREMIUM_INCLUSIVE
        ) {
          return (
            <PTag color={'notification-warning-soft'} icon={'information'}>
              <FormattedMessage {...messages.terminationProgress} />
            </PTag>
          );
        }

        return (
          <PTag color={'notification-warning-soft'} icon={'information'}>
            <FormattedMessage {...messages.terminated} />
          </PTag>
        );
      }

      //condition to check if price change is pending
      if (context.subscription) {
        return <PriceChangeTag subscription={context.subscription} />;
      }

      return null;
    }, [
      context.subscription?.endDate,
      context.subscription?.isTerminated,
      context.subscription,
    ]);

    const openOverview = useCallback(() => {
      navigate('./overview');
    }, [navigate]);

    return (
      <div className={isDisabled ? style.rootDisabled : style.root}>
        <div className={style.tile} onClick={openOverview}>
          <div className={style.container}>
            <PText
              weight="bold"
              color={isDisabled ? 'contrast-medium' : 'primary'}
            >
              <PremiumOnly>
                <FormattedMessage {...messages.premium} />
              </PremiumOnly>
              <BasicOnly>
                <FormattedMessage {...messages.basic} />
              </BasicOnly>
              <LegacyOnly>
                <FormattedMessage {...messages.legacy} />
              </LegacyOnly>
            </PText>
            <div className={style.tag}>
              {tag}
              {isDisabled ? null : <PButtonPure icon={'arrow-head-right'} />}
            </div>
          </div>
        </div>
      </div>
    );
  });

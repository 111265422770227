import React from 'react';
import style from './tariffOverview.module.scss';
import { BaseFee } from './baseFee/BaseFee';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { NetworkBarrier } from './networkBarrier/NetworkBarrier';
import { Duration } from './duration/Duration';
import { Tariff } from './tariff/Tariff';
import { Charging } from '../../../tariff/charging/Charging';
import { FlatTariff } from './flat/FlatTariff';
import { Subscription } from 'components/context/subscription/SubscriptionContext';

type TariffOverviewProps = {
  subscription?: Subscription | null;
};

export const TariffOverview: React.FunctionComponent<TariffOverviewProps> = ({
  subscription,
}) => {
  return (
    <div className={style.root}>
      <div className={style.table}>
        <NetworkBarrier>
          <Header />
          <div className={style.body}>
            <Tariff />
            <Duration />
            <BaseFee subscription={subscription} />
            <Charging />
            <FlatTariff />
          </div>
          <Footer />
        </NetworkBarrier>
      </div>
    </div>
  );
};

import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  PTabs,
  PTabsItem,
  PHeading,
  PText,
  PLinkPure,
  TabsUpdateEvent,
  PInlineNotification,
} from '@porsche-design-system/components-react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';

import { SubscriptionManagement } from './components/subscriptionManagement/SubscriptionManagement';
import { SubscriptionHistoryList } from './components/SubscriptionHistoryList/SubscriptionHistoryList';
import { messages } from './management.module.messages';

import { deviceContext } from '../../components/context/device/DeviceContextProvider';

import style from './management.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { OverviewFlyout } from '../../components/manage/overviewFlyout/OverviewFlyout';
import { SuspendedFlyout } from '../../components/manage/suspendedFlyout/SuspendedFlyout';
import { UpgradeContextProvider } from '../../components/context/upgrade/UpgradeContextProvider';
import { ReactivateTariff } from './components/ReactivateTariff/ReactivateTariff';
import { Routes } from 'router/routes';
import {
  subscriptionContext,
  TerminationReason,
} from 'components/context/subscription/SubscriptionContext';

export const ManagementPage: FC = observer(() => {
  const intl = useIntl();
  const subscription = useContext(subscriptionContext);
  const { returnUrl, isReturnUrlWeb } = useContext(deviceContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { vin, marketplace, locale } = useParams();

  useEffect(() => {
    const isOnlyManageRoute = /manage\b$/.test(location.pathname);

    if (isOnlyManageRoute) {
      navigate(`${location.pathname}/history`, { replace: true });
    }
  }, [location, navigate]);

  const activeTabIndex = useMemo(
    () => (location.pathname.includes('history') ? 0 : 1),
    [location.pathname],
  );

  const isSuspendedTerminated = useMemo(
    () =>
      !!(
        subscription.subscription?.isSuspended &&
        subscription.subscription?.isTerminated &&
        subscription.subscription.terminationReason ===
          TerminationReason.INVOICE_NOT_PAID
      ),
    [subscription.subscription],
  );

  const onActiveTabUpdate = useCallback(
    (event: CustomEvent<TabsUpdateEvent>) => {
      const isOnlyManageRoute = /manage\b$/.test(location.pathname);

      if (!isOnlyManageRoute) {
        const routePartToNavigateTo =
          event.detail.activeTabIndex === 0 ? 'history' : 'contract';

        if (location.pathname.includes(routePartToNavigateTo)) {
          return;
        }

        const pathnameSplited = location.pathname.split('/');
        const newPathname = pathnameSplited
          .slice(0, pathnameSplited.length - 1)
          .join('/');

        navigate(`${newPathname}/${routePartToNavigateTo}`);
      }
    },
    [navigate, location.pathname],
  );

  const suspendedNotificationAction = useCallback(() => {
    navigate('./suspended');
  }, [navigate]);

  const backButtonWebUrl = `${process.env.REACT_APP_MY_PORSCHE_URL}/core/${marketplace}/${locale}/vehicles/${vin}`;

  return (
    <UpgradeContextProvider>
      <div className={style.gridgrid}>
        <div className={style.mainContentWrapper}>
          <PLinkPure
            href={returnUrl ? returnUrl : backButtonWebUrl}
            icon="arrow-left"
            className={style.backButton}
          >
            {intl.formatMessage(
              isReturnUrlWeb
                ? messages.backButtonLabel
                : messages.appButtonLabel,
            )}
          </PLinkPure>

          <PHeading className={style.pageTitle}>
            {intl.formatMessage(messages.pageTitle)}
          </PHeading>

          <PText className={style.mainText}>
            {intl.formatMessage(messages.pageMainText)}
          </PText>

          <ReactivateTariff
            closeRedirectRoute={Routes.SubscriptionManage}
            isManagementPage
          />

          {subscription.subscription?.isSuspended ? (
            <PInlineNotification
              heading={intl.formatMessage(
                isSuspendedTerminated
                  ? messages.suspendedTerminatedNotificationHeading
                  : messages.suspendedNotificationHeading,
              )}
              description={intl.formatMessage(
                isSuspendedTerminated
                  ? messages.suspendedTerminatedNotificationDescription
                  : messages.suspendedNotificationDescription,
              )}
              state={'error'}
              dismissButton={false}
              className={style.suspendedNotification}
              actionLabel={intl.formatMessage(
                messages.suspendedNotificationAction,
              )}
              actionIcon="arrow-right"
              onAction={suspendedNotificationAction}
            />
          ) : null}

          <PTabs
            weight="semibold"
            size="medium"
            activeTabIndex={activeTabIndex}
            onUpdate={onActiveTabUpdate}
          >
            <PTabsItem
              label={intl.formatMessage(messages.historyTabLabel)}
              className={style.tabItem}
            >
              <SubscriptionHistoryList />
            </PTabsItem>
            <PTabsItem
              label={intl.formatMessage(messages.subscriptionManagementLabel)}
              className={style.tabItem}
            >
              <SubscriptionManagement isDisabled={isSuspendedTerminated} />
            </PTabsItem>
          </PTabs>
          <OverviewFlyout />
          {subscription.subscription?.isSuspended ? <SuspendedFlyout /> : null}
        </div>
      </div>
    </UpgradeContextProvider>
  );
});

import { FC } from 'react';
import { messages } from './taxPercentage.messages';
import { FormattedMessage } from 'react-intl';

type TaxPercentageProps = {
  taxPercentage?: number;
};

export const TaxPercentage: FC<TaxPercentageProps> = ({ taxPercentage }) => {
  if (!taxPercentage) {
    return null;
  }

  const normalizedPercentage = taxPercentage * 100;

  return (
    <FormattedMessage
      {...messages.taxPercentageText}
      values={{ tax: normalizedPercentage }}
    />
  );
};

import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { useCookieConsent } from 'hooks/useCookieConsent';
import { FC } from 'react';

export const NewRelic: FC = () => {
  const { cookieConsentLoaded, hasCookieConsent } =
    useCookieConsent('New Relic');

  if (process.env.REACT_APP_IS_NEW_RELIC_ENABLED && cookieConsentLoaded) {
    new BrowserAgent({
      init: {
        distributed_tracing: { enabled: true },
        privacy: {
          cookies_enabled: hasCookieConsent,
        },
        ajax: { deny_list: [process.env.REACT_APP_NEW_RELIC_BEACON] },
      },
      info: {
        beacon: process.env.REACT_APP_NEW_RELIC_BEACON,
        errorBeacon: process.env.REACT_APP_NEW_RELIC_BEACON,
        licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
        applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
        sa: 1,
      },
      loader_config: {
        accountID: process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID,
        trustKey: process.env.REACT_APP_NEW_RELIC_TRUST_KEY,
        agentID: process.env.REACT_APP_NEW_RELIC_AGENT_ID,
        licenseKey: process.env.REACT_APP_NEW_RELIC_LICENSE_KEY,
        applicationID: process.env.REACT_APP_NEW_RELIC_APPLICATION_ID,
      },
    });
  }

  return null;
};

import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { UpgradeContext } from './UpgradeContext';
import {
  Subscription,
  subscriptionContext,
} from '../subscription/SubscriptionContext';
import {
  Authentication,
  useAuthentication,
} from '../../../hooks/useAuthentication/useAuthentication';
import { useParams } from 'react-router-dom';

interface UpgradeContextProviderProps {
  children?: ReactNode;
}

const defaultContext = new UpgradeContext({
  dummy: true,
  auth: {} as Authentication,
  subscription: {} as Subscription,
  marketplace: 'XX',
});
export const upgradeContext = createContext<UpgradeContext>(defaultContext);

/**
 * Provides and rebuilds {@link UpgradeContext}
 */
export const UpgradeContextProvider: React.FunctionComponent<UpgradeContextProviderProps> =
  observer((props) => {
    const [context, setContext] = useState<UpgradeContext>(defaultContext);
    const auth = useAuthentication();
    const subscription = useContext(subscriptionContext);
    const params = useParams();

    useEffect(() => {
      if (
        !subscription.subscription ||
        !params.marketplace ||
        !auth.apiKey ||
        !auth.token ||
        !auth.ciamId
      ) {
        return;
      }
      setContext(
        new UpgradeContext({
          dummy: false,
          auth,
          subscription: subscription.subscription,
          marketplace: params.marketplace!,
        }),
      );
    }, [
      auth.apiKey,
      auth.token,
      auth.ciamId,
      subscription.subscription,
      params.marketplace,
    ]);

    return (
      <upgradeContext.Provider value={context}>
        {props.children}
      </upgradeContext.Provider>
    );
  });

import { useMemo } from 'react';
import { useSpecificPlans } from './useSpecificPlans';
import { Subscription } from 'components/context/subscription/SubscriptionContext';

type useShouldShowPriceUpdateOptions = {
  subscription?: Subscription | null;
};

export const useShouldShowPriceUpdate = ({
  subscription,
}: useShouldShowPriceUpdateOptions) => {
  const plans = useSpecificPlans({
    planVariant: subscription?.currentOrLastPhase?.plan?.variant,
  });

  const shouldShowPriceUpdate = useMemo(() => {
    if (
      !subscription ||
      !plans ||
      !Array.isArray(plans.plans) ||
      plans.plans?.length <= 1
    ) {
      return false;
    }

    return (
      plans.plans[0].pcsVersion !==
      subscription.currentOrLastPhase?.plan?.pcsVersion
    );
  }, [plans, subscription]);

  const dateOfPriceUpdate = useMemo(() => {
    if (!shouldShowPriceUpdate || !subscription) {
      return null;
    }

    return subscription.nextCustomerRelevantPhase?.startDate;
  }, [subscription, shouldShowPriceUpdate]);

  if (!subscription) {
    return { shouldShowPriceUpdate: false, dateOfPriceUpdate: null };
  }

  return { shouldShowPriceUpdate, dateOfPriceUpdate };
};

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  historyTabLabel: {
    id: 'management.historyTabLabel',
    defaultMessage: 'Charging history',
    description: 'Label history tab',
  },
  subscriptionManagementLabel: {
    id: 'management.subscriptionManagementLabel',
    defaultMessage: 'Subscription',
    description: 'Label subscription management',
  },
  pageTitle: {
    id: 'management.pageTitle',
    defaultMessage: 'Charging History and Subscription',
    description: 'Page title',
  },
  pageMainText: {
    id: 'management.pageMainText',
    defaultMessage:
      'Get an overview of your charging processes at public charging stations and manage your current charging contract.',
    description: 'Page main text',
  },
  suspendedNotificationHeading: {
    id: 'management.suspendedNotificationHeading',
    defaultMessage:
      'Your Porsche Charging Service Subscription has been suspended',
  },
  suspendedNotificationDescription: {
    id: 'management.suspendedNotificationDescription',
    defaultMessage:
      'Your subscription has been suspended due to missed payments. Once the overdue amount is paid, your subscription will automatically be reinstated.',
  },
  suspendedNotificationAction: {
    id: 'management.suspendedNotificationAction',
    defaultMessage: 'Payment information',
  },

  suspendedTerminatedNotificationHeading: {
    defaultMessage:
      'Your Porsche Charging Service Subscription has been terminated',
    id: 'management.suspendedTerminatedNotificationHeading',
  },
  suspendedTerminatedNotificationDescription: {
    defaultMessage:
      'We regret to inform you that your subscription has been terminated because the outstanding payment was not completed in due time. To resume your subscription, please ensure the overdue amount is paid in full.',
    id: 'management.suspendedTerminatedNotificationDescription',
  },

  backButtonLabel: {
    id: 'management.backButtonLabel',
    defaultMessage: 'Back to My Vehicle',
    description: 'Page back button',
  },
  appButtonLabel: {
    id: 'management.appButtonLabel',
    defaultMessage: 'Back to MyPorsche app',
    description: 'App back button',
  },
});

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loadingSubscription: {
    defaultMessage: 'Loading subscription...',
    id: 'tariff.change.select.wrapper.loading.subscription',
  },
  loadingTariffs: {
    defaultMessage: 'Loading available tariffs...',
    id: 'tariff.change.select.wrapper.loading.tariffs',
  },
  errorTitle: {
    defaultMessage: 'Unable to load tariffs',
    id: 'tariff.change.select.wrapper.error.title',
  },
  errorText: {
    defaultMessage: 'Please try again later',
    id: 'tariff.change.select.wrapper.error.text',
  },
  unavailableTitle: {
    defaultMessage: 'No tariffs available',
    id: 'tariff.change.select.wrapper.unavailable.title',
  },
  unavailableText: {
    defaultMessage: 'You are already on the best tariff available',
    id: 'termsAndConditionsAcceptPage.unavailable.text',
  },
  title: {
    defaultMessage: 'Important update',
    id: 'termsAndConditionsAcceptPage.title',
  },
  subTitle: {
    defaultMessage:
      "We've updated our {termsOfUse} and {termsAndConditions} to improve clarity and transparency. To continue enjoying our services, please review the details and accept the changes below.",
    id: 'termsAndConditionsAcceptPage.subTitle',
  },
  termsOfUse: {
    defaultMessage: 'Terms of Use',
    id: 'termsAndConditionsAcceptPage.termsOfUse',
  },
  termsAndConditions: {
    defaultMessage: 'Terms and Conditions',
    id: 'termsAndConditionsAcceptPage.termsAndConditions',
  },
  updateDateNotificationText: {
    defaultMessage: 'The new terms apply from {date}',
    id: 'termsAndConditionsAcceptPage.updateDateNotificationText',
  },
  actionButtonLabel: {
    defaultMessage: 'Accept and continue',
    id: 'termsAndConditionsAcceptPage.actionButtonLabel',
  },
  cancelActionButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'termsAndConditionsAcceptPage.cancelActionButtonLabel',
  },
  overviewTitle: {
    defaultMessage: 'Changes overview',
    id: 'termsAndConditionsAcceptPage.overviewTitle',
  },
  successMessage: {
    defaultMessage: 'Updated Terms of Use and Terms and Conditions',
    id: 'termsAndConditionsAcceptPage.successMessage',
  },
  errorMessage: {
    defaultMessage: 'Something went wrong, please try again later',
    id: 'termsAndConditionsAcceptPage.errorMessage',
  },
  // changesText is used for showing all the changes from previous to new terms and conditions
  // content is dynamic from Phrase and not maintained by myCharging team
  // to use content dynamically, we use separators to separate the content into smaller sections, each section can contain a title for whole section, text (multiple subtitles allowed and separated with small marging) that follows title (subtitle),
  // following title and subtitle we can have a list smaller indented sections, each with title and subtitle
  // separator strings are used to separate texts and it is put before title/subtitle
  // section separator (-s-) is used to separate sections
  // title separator (-t-) is used to get titles of each section
  // subtitle separator (-st-) is used to get subtitles of each section
  // list separator (-l-) is used to separate list items
  // list title separator (-lt-) is used to get list title
  // list subtitle separator (-lst-) is used to get list subtitle
  // example of content:
  //  (-s-)
  //  (-t-)Section title
  //  (-st-)Section subtitle
  //  (-st-)Section subtitle 2
  //  (-l-)
  //  (-lt-)List title
  //  (-lst-)List subtitle
  //  (-l-)
  //  (-lt-)List title 2
  //  (-lst-)List subtitle 2
  //  (-s-)
  //  (-t-)Section title 2
  //  (-st-)Section subtitle 3
  //  (-l-)
  //  (-lt-)List title 3
  //  (-lst-)List subtitle 3
  //  (-l-)
  //  (-lt-)List title 4
  //  (-lst-)List subtitle 4
  //  (-s-)
  //  (-t-)Section title 3
  //  (-st-)Section subtitle 5
  //  (-l-)
  //  (-lt-)List title 5
  //  (-lst-)List subtitle 5
  changesText: {
    defaultMessage:
      '(-s-)(-t-)Section title(-st-)Section subtitle(-st-)Section subtitle 2(-l-)(-lt-)List title(-lst-)List subtitle(-l-)(-lt-)List title 2(-lst-)List subtitle 2(-s-)(-t-)Section title 2(-st-)Section subtitle 3(-l-)(-lt-)List title 3(-lst-)List subtitle 3(-l-)(-lt-)List title 4(-lst-)List subtitle 4(-s-)(-t-)Section title 3(-st-)Section subtitle 5(-l-)(-lt-)List title 5(-lst-)List subtitle 5',
    id: 'termsAndConditionsAcceptPage.changesText',
  },
});

import { useScript } from 'hooks/useScript';
import { FC } from 'react';

export const CookieConsent: FC = () => {
  const loadingScript = useScript(
    'https://www.porsche.com/redesign-scripts/vendor/udg-uc-sdk.min.js',
  );

  if (loadingScript === 'ready') {
    console.log('CookieConsent script loaded');
  }

  return null;
};

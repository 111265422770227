import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { subscriptionContext } from '../../../components/context/subscription/SubscriptionContext';
import { NetworkStatus } from '../../../hooks/useCombinedNetworking/useCombinedNetworking';
import style from './termsAndConditionsAcceptPage.module.scss';
import {
  PButton,
  PHeading,
  PIcon,
  PSpinner,
  PTag,
  PText,
  useToastManager,
} from '@porsche-design-system/components-react';
import { FrostedLayout } from '../../../components/layout/frosted/FrostedLayout';
import { messages } from './termsAndConditionsAcceptPage.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import background from '../../../media/backgroundPriceUpdate.png';
import { useNavigate } from 'react-router-dom';
import { useRoutes } from 'hooks/useRoutes';
import { Routes } from 'router/routes';
import useTermsAndConditions from 'hooks/useTermsAndConditions';
import { TermsAndConditions } from 'components/tac/TermsAndConditions';

export const TermsAndConditionsAcceptPage: FC = observer(() => {
  const { addMessage } = useToastManager();
  const intl = useIntl();
  const navigate = useNavigate();
  const generateRoute = useRoutes();
  const subscription = useContext(subscriptionContext);
  const {
    checkForNewTermsAndConditions,
    upgradeTermsAndConditions,
    isTermsLoading,
    upgradeEndDate,
    isUpgradeLoading,
    tacDoc,
    touDoc,
    isCheckForNewTermsEnabled,
  } = useTermsAndConditions(subscription?.subscription?.id);
  const [isAccepted, setIsAccepted] = useState(false);

  const handleAccept = async () => {
    try {
      await upgradeTermsAndConditions();
      addMessage({
        text: intl.formatMessage(messages.successMessage),
        state: 'success',
      });
      navigate(generateRoute(Routes.SubscriptionManage));
    } catch (error: unknown) {
      console.error(error);
      addMessage({
        text: intl.formatMessage(messages.errorMessage),
        state: 'info',
      });
    }
  };

  const formattedDate =
    upgradeEndDate &&
    Intl.DateTimeFormat('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(upgradeEndDate));

  useEffect(() => {
    if (!subscription?.subscription?.id) {
      return;
    }

    checkForNewTermsAndConditions();
  }, [subscription?.subscription?.id]);

  const loadingText = useMemo(() => {
    if (subscription.network !== NetworkStatus.Success || isTermsLoading) {
      return <FormattedMessage {...messages.loadingSubscription} />;
    }
    return <FormattedMessage {...messages.loadingTariffs} />;
  }, [subscription.network]);

  if (!isCheckForNewTermsEnabled) {
    return null;
  }

  return (
    <FrostedLayout background={background}>
      {subscription.network === NetworkStatus.Loading ? (
        <div className={style.center}>
          <PSpinner />
          <PText>{loadingText}</PText>
        </div>
      ) : null}

      {subscription.network === NetworkStatus.Error ||
      subscription.subscription === null ? (
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.errorTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.errorText} />
          </PText>
        </div>
      ) : null}

      {subscription.subscription ? (
        <div className={style.contentContainer}>
          <PHeading>
            <FormattedMessage {...messages.title} />
          </PHeading>

          <PText>
            <FormattedMessage
              {...messages.subTitle}
              values={{
                termsAndConditions: (
                  <a href={tacDoc || '#'} target="__blank">
                    <FormattedMessage {...messages.termsAndConditions} />
                  </a>
                ),
                termsOfUse: (
                  <a href={touDoc || '#'} target="__blank">
                    <FormattedMessage {...messages.termsOfUse} />
                  </a>
                ),
              }}
            />
          </PText>

          <PTag icon="chat" color="notification-info-soft">
            <FormattedMessage
              {...messages.updateDateNotificationText}
              values={{
                date: formattedDate,
              }}
            />
          </PTag>

          <TermsAndConditions
            checked={isAccepted}
            onChange={() => setIsAccepted((previousState) => !previousState)}
          />

          <div>
            <PButton
              disabled={!isAccepted}
              loading={isUpgradeLoading}
              onClick={handleAccept}
            >
              <FormattedMessage {...messages.actionButtonLabel} />
            </PButton>
            <PButton
              onClick={() => navigate(generateRoute(Routes.SubscriptionManage))}
              variant="secondary"
              className={style.cancelButton}
            >
              <FormattedMessage {...messages.cancelActionButtonLabel} />
            </PButton>
          </div>
        </div>
      ) : null}
    </FrostedLayout>
  );
});

import { useEffect, useState } from 'react';

export type ScriptState = 'ready' | 'loading' | 'error';
export type ScriptModule = 'module' | 'nomodule';

const states: Record<string, ScriptState> = {};
export const callbacks: Record<
  string,
  Array<(addedScript: 'ready' | 'loading' | 'error') => any>
> = {};

const successHandler = (src: string) => () => {
  states[src] = 'ready';
  while (callbacks[src].length > 0) {
    callbacks[src].pop()!('ready');
  }
};
const errorHandler = (src: string) => () => () => {
  states[src] = 'error';
  while (callbacks[src].length > 0) {
    callbacks[src].pop()!('error');
  }
};

const findScript = (src: string) =>
  document.querySelector(`script[src="${src}"]`);

export const addScript = (
  src: string,
  callback: (addedScript: ScriptState) => any,
  moduleAttribute?: ScriptModule,
) => {
  if (!callbacks[src]) {
    callbacks[src] = [];
  }
  callbacks[src].push(callback);

  const existingScript = findScript(src);

  if (existingScript) {
    return callback(states[src]);
  }

  const scriptTag: HTMLScriptElement = document.createElement('script');
  scriptTag.async = false;
  scriptTag.src = src;
  scriptTag.type = 'text/javascript';

  if (moduleAttribute === 'module') {
    scriptTag.type = 'module';
  } else if (moduleAttribute === 'nomodule') {
    scriptTag.noModule = true;
  }

  scriptTag.addEventListener('load', successHandler(src));
  scriptTag.addEventListener('error', errorHandler(src));

  try {
    document.head.appendChild(scriptTag);
  } catch (e) {
    console.error('Failed to append script element', e);
  }

  return () => {
    const existingScript = findScript(src);
    if (existingScript) {
      existingScript.removeEventListener('load', successHandler(src));
      existingScript.removeEventListener('error', errorHandler(src));
    }
  };
};

export const useScript = (
  src: string,
  moduleAttr?: ScriptModule,
): ScriptState => {
  const [status, setStatus] = useState<ScriptState>('loading');

  useEffect(() => {
    if (status !== 'ready') {
      return addScript(
        src,
        () => {
          setStatus('ready');
        },
        moduleAttr,
      );
    }
  }, [src, moduleAttr, status]);

  return status;
};

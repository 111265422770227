import React, { useCallback, useContext } from 'react';
import { PFlyout, PHeading } from '@porsche-design-system/components-react';
import { TariffOverview } from './overview/TariffOverview';
import style from './flyout.module.scss';
import { CustomerService } from './customerService/CustomerService';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlanContextProvider } from '../../context/plan/PlanContextProvider';
import { subscriptionContext } from '../../context/subscription/SubscriptionContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Terminate } from './terminate/Terminate';
import { messages } from './flyout.messages';
import { UpgradeNotification } from '../notifications/UpgradeNotification';
import { ReactivateTariff } from 'pages/management/components/ReactivateTariff/ReactivateTariff';
import { Routes } from 'router/routes';
import { SuspendedNotificaion } from '../notifications/suspended/Suspended';
import { Reactivate } from '../flyout/reactivate/Reactivate';

export const OverviewFlyout: React.FunctionComponent = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const isOverview =
    location.pathname.match(/.*\/contract\/overview$/i) !== null;
  const isReactivation =
    location.pathname.match(/.*\/contract\/reactivation$/i) !== null;
  const open = isOverview || isReactivation;

  const intl = useIntl();
  const subscription = useContext(subscriptionContext);

  const onClose = useCallback(() => {
    navigate('./..');
  }, [navigate]);

  return (
    <PlanContextProvider
      plan={subscription.subscription?.currentOrLastPhase.plan || null}
      marketplace={subscription.subscription?.country || 'US'}
      locale={intl.locale}
    >
      <PFlyout open={open} onDismiss={onClose}>
        <span slot={'header'}>
          <PHeading>
            <FormattedMessage {...messages.title} />
          </PHeading>
        </span>

        <div className={style.root}>
          <ReactivateTariff closeRedirectRoute={Routes.Overview} />

          <SuspendedNotificaion hasAction={true} />
          <UpgradeNotification dismissible={false} />

          <TariffOverview subscription={subscription.subscription} />

          {isReactivation ? <Reactivate /> : null}

          <div>
            <Terminate />
          </div>

          <div className={style.customerService}>
            <CustomerService />
          </div>
        </div>
      </PFlyout>
    </PlanContextProvider>
  );
});

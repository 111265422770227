import { useEffect, useState } from 'react';

let cookieConsentIntervals = {} as {
  [service: string]: ReturnType<typeof setInterval> | undefined;
};

export const useCookieConsent = (service: string) => {
  const [cookieConsentLoaded, setCookieConsentLoaded] = useState(false);
  const [hasCookieConsent, setHasCookieConsent] = useState(false);

  useEffect(() => {
    let isConsented = false;

    if (cookieConsentLoaded && window.usercentrics) {
      try {
        const consent = window.usercentrics
          .getConsents()
          .find(
            ({ dataProcessingService }) => dataProcessingService === service,
          );

        if (consent?.consentStatus) {
          isConsented = true;
        }
      } catch (error) {
        console.error('Consents not found', error);
      }
    }

    setHasCookieConsent(isConsented);
  }, [service, cookieConsentLoaded]);

  useEffect(() => {
    cookieConsentIntervals[service] = setInterval(() => {
      if (window.usercentrics) {
        setCookieConsentLoaded(true);
        clearInterval(cookieConsentIntervals[service]);
      }
    }, 100);

    return () => clearInterval(cookieConsentIntervals[service]);
  }, []);

  return {
    cookieConsentLoaded,
    hasCookieConsent,
  };
};

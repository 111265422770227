import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Subscription termination in progress',
    id: 'reactivationTag.title',
  },
  text: {
    defaultMessage:
      'Your subscription will be terminated on {date}. You can reactivate it any time.',
    id: 'reactivationTag.text',
  },
  reactivationButtonLabel: {
    defaultMessage: 'Reactivate subscription',
    id: 'reactivationTag.reactivationButtonLabel',
  },
});

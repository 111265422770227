import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  heading: {
    defaultMessage:
      'Your Porsche Charging Service Subscription has been suspended',
    id: 'manage.notifications.suspended.heading',
  },
  description: {
    defaultMessage:
      'Your subscription has been suspended due to missed payments. Once the overdue amount is paid, your subscription will automatically be reinstated.',
    id: 'manage.notifications.suspended.description',
  },
});

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  premium: {
    defaultMessage: 'Porsche Charging Premium',
    id: 'manage.overview.tile.title.premium',
  },
  basic: {
    defaultMessage: 'Porsche Charging Basic',
    id: 'manage.overview.tile.title.basic',
  },
  legacy: {
    defaultMessage: 'Porsche Charging Service',
    id: 'manage.overview.tile.title.legacy',
  },
  terminated: {
    defaultMessage: 'Contract terminated',
    id: 'manage.overview.tile.tag.terminated',
  },
  terminationProgress: {
    defaultMessage: 'Subscription termination in progress',
    id: 'manage.overview.tile.tag.terminationProgress',
  },
});

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addressPreviewTitle: {
    id: 'subscriptionManagement.addressPreviewTitle',
    defaultMessage: 'Billing address',
    description: 'Title of address widget preview',
  },
  chargePointTitle: {
    id: 'subscriptionManagement.chargePointTitle',
    defaultMessage: 'ChargePoint',
    description: 'Title of ChargePoint registration status widget',
  },
  chargePointRegistrationAvailable: {
    id: 'subscriptionManagement.chargePointRegistrationAvailableText',
    defaultMessage:
      'You have no ChargePoint Account, please create your ChargePoint account in the MyPorsche App.',
    description: 'Text for ChargePoint registration MyPorsche App hint',
  },
  chargePointRegistrationPending: {
    id: 'subscriptionManagement.chargePointRegistrationPendingText',
    defaultMessage: 'Your ChargePoint account is currently being created.',
    description: 'Text for ChargePoint registration pending',
  },
  addressRequiresValidationError: {
    defaultMessage: 'Address requires additional validation',
    id: 'subscriptionManagement.addressRequiresValidationError.error',
  },
  addressNotAllowedError: {
    defaultMessage: 'Address must be in the current marketplace',
    id: 'subscriptionManagement.addressNotAllowed.error',
  },
  addressTypeNotAllowedError: {
    defaultMessage: 'Can not switch between business and home address.',
    id: 'subscriptionManagement.addressTypeNotAllowedError.error',
  },
  paymentPreviewTitle: {
    id: 'subscriptionManagement.paymentPreviewTitle',
    defaultMessage: 'Payment method for charging transactions',
    description: 'Title of payment widget preview',
  },
  editButtonText: {
    id: 'subscriptionManagement.editButtonText',
    defaultMessage: 'Edit',
    description: 'Edit button text',
  },
  submitButtonText: {
    id: 'subscriptionManagement.submitButtonText',
    defaultMessage: 'Submit',
    description: 'Submit button text',
  },
  cancelButtonText: {
    id: 'subscriptionManagement.cancelButtonText',
    defaultMessage: 'Cancel',
    description: 'Cancel button text',
  },
  noSubscription: {
    id: 'subscriptionManagement.noSubscription',
    defaultMessage: 'No subscription found',
  },
  expired: {
    id: 'subscriptionManagement.expired',
    defaultMessage: 'Your subscription expired',
  },
  networkError: {
    id: 'subscriptionManagement.networkError',
    defaultMessage: 'A network error occurred',
  },
  unknownPaymentMessagge: {
    id: 'subscriptionManagement.unknownPaymentMessagge',
    defaultMessage: 'Unknown payment method',
    description: 'Unknow payment method messagge',
  },
  suspendedPaymentMessage: {
    id: 'subscriptionManagement.suspendedPaymentMessage',
    defaultMessage: 'Make sure that your payment method is up to date',
  },
  invoicesLabel: {
    id: 'subscriptionManagement.invoicesLabel',
    defaultMessage: 'Rechnungen',
    description: 'Label for invoices',
  },
  rfidTitle: {
    id: 'subscriptionManagement.rfidTitle',
    defaultMessage: 'Your active charging card',
    description: 'RFID section title',
  },
  rfidSubtitle: {
    id: 'subscriptionManagement.rfidSubtitle',
    defaultMessage: 'RFID Card Number',
    description: 'RFID section subtitle text',
  },
  rfidButtonText: {
    id: 'subscriptionManagement.rfidButtonText',
    defaultMessage: 'Karte verwalten',
    description: 'RFID section external link text',
  },
  contractsTitle: {
    id: 'subscriptionManagement.contractsTitle',
    defaultMessage: 'My Porsche Charging Service',
    description: 'Contracts section title',
  },
  updateActionSuccessText: {
    id: 'subscriptionManagement.updateActionSuccessText',
    defaultMessage: 'Update successful',
    description: 'Subscription update action toast message for success',
  },
  updateActionFailText: {
    id: 'subscriptionManagement.updateActionFailText',
    defaultMessage: 'Failed to update!',
    description: 'Subscription update action toast message for success',
  },
  loading: {
    id: 'subscriptionManagement.loading',
    defaultMessage: 'Loading subscription...',
    description: 'Loading spinner sub text',
  },
  paymentMethodNotificationHeaderText: {
    id: 'subscriptionManagement.paymentMethodNotificationHeaderText',
    defaultMessage: 'Your preferred payment method',
    description: 'Payment widget notification header text',
  },
  paymentMethodNotificationContentText: {
    id: 'subscriptionManagement.paymentMethodNotificationContentText',
    defaultMessage:
      'By changing this payment method, your preferred Porsche ID payment method will remain the same. You can change your preferred payment method in the {porscheIdProfileSettings}',
    description: 'Payment widget notification content text',
  },
  porscheIdProfileSettings: {
    id: 'subscriptionManagement.porscheIdProfileSettings',
    defaultMessage: 'Porsche ID profile settings',
    description:
      'Payment widget notification text that will be link to Porsche ID profile settings',
  },
  bannerTitle: {
    id: 'subscriptionManagement.bannerTitle',
    defaultMessage: 'Order the Charging Service',
    description: 'No subscription banner title',
  },
  bannerSubtitle: {
    id: 'subscriptionManagement.bannerSubtitle',
    defaultMessage:
      'With brand new Porsche Charging Service subscription you can now charge at attractive conditions - with full flexibility.',
    description: 'No subscription banner subtitle',
  },
  bannerButtonText: {
    id: 'subscriptionManagement.bannerButtonText',
    defaultMessage: 'Activate Subscription',
    description: 'No subscription banner action button text',
  },
  termsNotificationTitle: {
    id: 'subscriptionManagement.termsNotificationTitle',
    defaultMessage: 'Important update: Terms and Conditions',
    description:
      'Title to use on notification for updating terms and conditions',
  },
  termsNotificationSubtitle: {
    id: 'subscriptionManagement.termsNotificationSubtitle',
    defaultMessage:
      "We've updated our {termsOfUse} and {termsAndConditions} to improve clarity and transparency. To continue enjoying our services, please review the details and accept the changes below.",
    description:
      'Subtitle to use on notification for updating terms and conditions',
  },
  termsAndConditions: {
    id: 'subscriptionManagement.termsAndConditions',
    defaultMessage: 'Terms and Conditions',
    description: 'Terms and Conditions link text',
  },
  termsOfUse: {
    id: 'subscriptionManagement.termsOfUse',
    defaultMessage: 'Terms of Use',
    description: 'Terms of Use link text',
  },
  termsNotificationButtonLabel: {
    id: 'subscriptionManagement.termsNotificationButtonLabel',
    defaultMessage: 'Review Terms and Conditions',
    description: 'Button label navigating to review terms and conditions',
  },
});

import { useCallback } from 'react';
import { fetchApi } from 'utils/fetchApi';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';

type Address = {
  addressId: string;
  ciamId: string;
  usage: string;
  street1: string;
  houseNumber: string;
  city: string;
  postalCode: string;
  postalCodeRegion: string;
  postalCodeRegionValue: string;
  country: string;
  isStandard: boolean;
  isExpressBuyAddress: boolean;
  isBillingAddress: boolean;
  isDeliveryAddress: boolean;
  vatId?: string;
  coName?: string;
  companyName1?: string;
};

export const useFetchAddress = () => {
  const { token } = useAuthentication();

  const fetchAddressById = useCallback(
    async (addressId: string): Promise<Address | null> => {
      if (!addressId) return null;

      try {
        const response = await fetchApi(
          `${process.env.REACT_APP_MY_PROFILE_API_URL}/addresses/${addressId}`,
          token,
        );
        if (!response.ok) {
          throw new Error('There was an error with fetching the address data');
        }
        return await response.json();
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [token],
  );

  return { fetchAddressById };
};

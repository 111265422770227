import React, { ReactNode, useEffect, useState } from 'react';
import {
  featureFlagsContext,
  featureFlagsDummyContext,
  FeatureFlagsContext,
} from './FeatureFlagsContext';
import { observer } from 'mobx-react-lite';
import { useAuthentication } from 'hooks/useAuthentication/useAuthentication';

interface SubscriptionContextProviderProps {
  children?: ReactNode;
}

/**
 * Manages and rebuilds the SubscriptionContext
 */
export const FeatureFlagsContextProvider: React.FunctionComponent<SubscriptionContextProviderProps> =
  observer((props) => {
    const [context, setContext] = useState(featureFlagsDummyContext);
    const auth = useAuthentication();

    useEffect(() => {
      if (!auth?.token) {
        return;
      }

      setContext(
        new FeatureFlagsContext({
          dummy: false,
          auth: auth,
        }),
      );
    }, [auth]);

    return (
      <featureFlagsContext.Provider value={context}>
        {props.children}
      </featureFlagsContext.Provider>
    );
  });

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TariffSelection } from './tariffSelection/TariffSelection';
import { observer } from 'mobx-react-lite';
import { Title } from './title/Title';
import { Text } from './text/Text';
import { TermsAndConditions } from 'components/tac/TermsAndConditions';
import style from './selectPageContent.module.scss';
import { Action } from './action/Action';
import { PlanVariant } from '../../../components/context/plan/planContext.types';
import { subscriptionContext } from '../../../components/context/subscription/SubscriptionContext';
import { useAuthentication } from '../../../hooks/useAuthentication/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { upgradePlansContext } from '../../../components/context/upgrade/UpgradePlansContextProvider';
import { AdditionalDisclaimer } from './additionalDisclaimer/AdditionalDisclaimer';
import { getRegion } from 'utils/getRegion';
import { Region } from 'hooks/usePlans';

export const SelectPageContent: React.FunctionComponent = observer(() => {
  const planContext = useContext(upgradePlansContext);
  const subscription = useContext(subscriptionContext);
  const auth = useAuthentication();
  const navigate = useNavigate();
  const region = useMemo(
    () => getRegion(subscription.subscription?.country || ''),
    [subscription],
  );
  const isEuRegion = useMemo(() => region === Region.EU, [region]);

  /**
   * TOS checked
   */
  const [checked, setChecked] = useState<boolean>(false);
  /**
   * Current plan variant selected, even if only one is available it still will be "selected"
   */
  const [selected, setSelected] = useState<PlanVariant>(
    planContext.plans[0].variant,
  );
  /**
   * Loading state for user action, disables all user interactions
   */
  const [loading, setLoading] = useState(false);

  /**
   * Handle terms and conditions checkbox click
   */
  const onTermsAndConditionsClick = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  /**
   * Set initial selected plan variant
   */
  useEffect(() => {
    if (planContext.plans[0]) {
      setSelected(planContext.plans[0].variant);
    }
  }, [planContext.plans]);

  /**
   * Update the subscription server side and on success also client side
   */
  const onSave = useCallback(() => {
    (async () => {
      setLoading(true);

      if (!subscription.subscription) {
        navigate('./error');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/my/subscriptions/${subscription.subscription.id}/change`,
          {
            method: 'POST',
            body: JSON.stringify({ planVariant: selected }),
            headers: {
              'Authorization': `Bearer ${auth.token}`,
              'apikey': auth.apiKey,
              'Content-Type': 'application/json',
            },
          },
        );
        if (!response.ok) {
          navigate('./error');
          setLoading(false);
          return;
        }

        const result = await response.json();
        subscription.updateSubscription(result);

        navigate('./success');
        setLoading(false);
      } catch (e: unknown) {
        console.error(e);
        navigate('./error');
        setLoading(false);
      }
    })();
  }, [auth.apiKey, auth.token, navigate, selected, subscription]);

  return (
    <div
      className={style.root}
      style={{
        maxWidth: planContext.plans.length === 1 ? '720px' : 'unset',
      }}
    >
      <Title />
      <Text />
      <TariffSelection
        onChange={setSelected}
        planVariant={selected}
        disabled={loading}
        plans={planContext.plans}
        marketplace={subscription.subscription?.country || 'DE'}
      />
      <TermsAndConditions
        checked={checked}
        onChange={onTermsAndConditionsClick}
        disabled={loading}
      />
      <Action disableAction={!checked} loading={loading} onClick={onSave} />
      {isEuRegion ? (
        <AdditionalDisclaimer
          planVariants={planContext.planVariants}
          noSpacing
        />
      ) : null}
    </div>
  );
});

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loadingSubscription: {
    defaultMessage: 'Loading subscription...',
    id: 'tariff.change.select.wrapper.loading.subscription',
  },
  loadingTariffs: {
    defaultMessage: 'Loading available tariffs...',
    id: 'tariff.change.select.wrapper.loading.tariffs',
  },
  errorTitle: {
    defaultMessage: 'Unable to load tariffs',
    id: 'tariff.change.select.wrapper.error.title',
  },
  errorText: {
    defaultMessage: 'Please try again later',
    id: 'tariff.change.select.wrapper.error.text',
  },
  unavailableTitle: {
    defaultMessage: 'No tariffs available',
    id: 'tariff.change.select.wrapper.unavailable.title',
  },
  unavailableText: {
    defaultMessage: 'You are already on the best tariff available',
    id: 'priceAcceptPage.unavailable.text',
  },
  title: {
    defaultMessage: 'Pricing update',
    id: 'priceAcceptPage.title',
  },
  subTitle: {
    defaultMessage: 'Starting from {date}, our prices will be updated',
    id: 'priceAcceptPage.subTitle',
  },
  updateDateNotificationText: {
    defaultMessage: 'The new price will be applied starting from {date}',
    id: 'priceAcceptPage.updateDateNotificationText',
  },
  actionButtonLabel: {
    defaultMessage: 'I accept',
    id: 'priceAcceptPage.actionButtonLabel',
  },
});

import { useParams } from 'react-router-dom';

enum Region {
  EU = 'EU',
  US = 'US',
  CA = 'CA',
  NONE = 'NONE',
}
export const getRegion = (country?: string) => {
  if (!country) {
    return '';
  }

  // TODO: Refactor so it uses new marketplace configuration
  switch (country.toUpperCase()) {
    case 'AT':
    case 'BE':
    case 'CH':
    case 'CZ':
    case 'DE':
    case 'DK':
    case 'EE':
    case 'ES':
    case 'FI':
    case 'FR':
    case 'GB':
    case 'HR':
    case 'HU':
    case 'IE':
    case 'IT':
    case 'LI':
    case 'LT':
    case 'LU':
    case 'LV':
    case 'NL':
    case 'NO':
    case 'PL':
    case 'PT':
    case 'SE':
    case 'SI':
    case 'SK':
      return Region.EU;
    case 'US':
      return Region.US;
    case 'CA':
      return Region.CA;
    default:
      return Region.NONE;
  }
};
export const useRegion = () => {
  const params = useParams<{ marketplace: string }>();
  const country = params.marketplace?.toUpperCase();
  return getRegion(country);
};

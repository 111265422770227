import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { upgradePlansContext } from '../../../components/context/upgrade/UpgradePlansContextProvider';
import { subscriptionContext } from '../../../components/context/subscription/SubscriptionContext';
import {
  NetworkStatus,
  useCombinedNetworking,
} from '../../../hooks/useCombinedNetworking/useCombinedNetworking';
import style from './selectPage.module.scss';
import {
  PIcon,
  PSpinner,
  PText,
} from '@porsche-design-system/components-react';
import { FrostedLayout } from '../../../components/layout/frosted/FrostedLayout';
import { upgradeContext } from '../../../components/context/upgrade/UpgradeContextProvider';
import { messages } from './selectPage.messages';
import { FormattedMessage } from 'react-intl';
import { SelectPageContent } from './SelectPageContent';

/**
 * Wrapper around {@link SelectPageContent} for networking
 */
export const SelectPage: React.FunctionComponent = observer(() => {
  const upgrade = useContext(upgradeContext);
  const planContext = useContext(upgradePlansContext);
  const subscription = useContext(subscriptionContext);

  const network = useCombinedNetworking([
    upgrade.network,
    subscription.network,
    planContext.network,
  ]);

  const loadingText = useMemo(() => {
    if (subscription.network !== NetworkStatus.Success) {
      return <FormattedMessage {...messages.loadingSubscription} />;
    }
    return <FormattedMessage {...messages.loadingTariffs} />;
  }, [subscription.network]);

  if (network === NetworkStatus.Loading) {
    return (
      <FrostedLayout>
        <div className={style.center}>
          <PSpinner />
          <PText>{loadingText}</PText>
        </div>
      </FrostedLayout>
    );
  }

  if (network === NetworkStatus.Error || subscription.subscription === null) {
    return (
      <FrostedLayout>
        <div className={style.center}>
          <PIcon
            name={'close'}
            size={'large'}
            aria={{ 'aria-label': 'Close icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.errorTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.errorText} />
          </PText>
        </div>
      </FrostedLayout>
    );
  }

  if (planContext.plans.length === 0) {
    return (
      <FrostedLayout>
        <div className={style.center}>
          <PIcon
            name={'check'}
            size={'large'}
            aria={{ 'aria-label': 'Check icon' }}
          />
          <PText size={'medium'}>
            <FormattedMessage {...messages.unavailableTitle} />
          </PText>
          <PText size={'small'}>
            <FormattedMessage {...messages.unavailableText} />
          </PText>
        </div>
      </FrostedLayout>
    );
  }

  return (
    <FrostedLayout>
      <SelectPageContent />
    </FrostedLayout>
  );
});

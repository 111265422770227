import { PTag } from '@porsche-design-system/components-react';
import { FC } from 'react';
import { messages } from './priceChangeTag.messages';
import { FormattedMessage } from 'react-intl';
import { Subscription } from 'components/context/subscription/SubscriptionContext';
import { useShouldShowPriceUpdate } from 'hooks/useShouldShowPriceUpdate';

type PriceChangeTagProps = { subscription: Subscription };

export const PriceChangeTag: FC<PriceChangeTagProps> = ({ subscription }) => {
  const { shouldShowPriceUpdate, dateOfPriceUpdate } = useShouldShowPriceUpdate(
    { subscription },
  );
  const formattedDate =
    dateOfPriceUpdate &&
    Intl.DateTimeFormat('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(dateOfPriceUpdate));

  if (!shouldShowPriceUpdate || !dateOfPriceUpdate) {
    return null;
  }

  return (
    <PTag color={'notification-info-soft'} icon={'information'}>
      <FormattedMessage
        {...messages.mainMessage}
        values={{ date: formattedDate }}
      />
    </PTag>
  );
};

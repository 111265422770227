import { FC, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

type OAuthLoaderResponse =
  | { message: string; error: Error }
  | { callbackUrl: string };

export const OauthErrorPage: FC = () => {
  const navigate = useNavigate();

  // TODO: Workaround until https://github.com/remix-run/react-router/pull/11100 is merged
  const data = useLoaderData() as OAuthLoaderResponse;

  useEffect(() => {
    if ('callbackUrl' in data) {
      return navigate(data.callbackUrl, { replace: true });
    }
  }, [data, navigate]);

  if ('callbackUrl' in data) {
    return null;
  }

  const { error } = data;
  return <div>{error.message}</div>;
};

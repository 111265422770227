import React, { useMemo } from 'react';
import { PlanContextProvider } from '../../context/plan/PlanContextProvider';
import { useIntl } from 'react-intl';
import { PlanVariant, RawPlan } from '../../context/plan/planContext.types';
import { PHeading, PText } from '@porsche-design-system/components-react';
import { TariffName } from '../components/name/TariffName';
import { BaseFee } from '../components/baseFee/BaseFee';
import { Charging } from '../charging/Charging';
import style from './inlineTariff.module.scss';
import { messages } from './inlineTariff.messages';
import { Disclaimer } from '../components/disclaimer/Disclaimer';
import { Membership } from '../components/membership/Membership';
import { EuropeOnly } from '../../context/plan/discriminators/region/EuropeOnly';
import { InclusivePeriod } from '../components/inclusivePeriod/InclusivePeriod';
import { NorthAmericaOnly } from '../../context/plan/discriminators/region/NorthAmericaOnly';
import { BasicOnly } from '../../context/plan/discriminators/tariff/BasicOnly';
import { NarPremiumOnly } from '../../context/plan/discriminators/tariff/NarPremiumOnly';

interface InlinePlanProps {
  marketplace: string;
  plan: RawPlan;
  removeDisclaimer?: boolean;
  v2PremiumPlan?: RawPlan | null;
  newPriceUpdatePlan?: RawPlan | null;
}

export const InlineTariff: React.FunctionComponent<InlinePlanProps> = (
  props,
) => {
  const intl = useIntl();

  const disclaimer = useMemo(() => {
    if (props.removeDisclaimer) {
      return {
        component: null,
        style: style.removeDisclaimer,
      };
    }

    return {
      component: (
        <>
          <NorthAmericaOnly>
            <BasicOnly>
              <PText
                size={'x-small'}
                color={'contrast-medium'}
                className={style.disclaimerNA}
              >
                <Disclaimer />
              </PText>
            </BasicOnly>
            <NarPremiumOnly>
              <PText
                size={'x-small'}
                color={'contrast-medium'}
                className={style.disclaimerNA}
              >
                <Disclaimer />
              </PText>
            </NarPremiumOnly>
          </NorthAmericaOnly>
          <EuropeOnly>
            <PText size={'x-small'} color={'contrast-medium'}>
              <Disclaimer />
            </PText>
          </EuropeOnly>
        </>
      ),
      style: '',
    };
  }, [props.removeDisclaimer]);

  return (
    <div className={style.root}>
      <PlanContextProvider
        plan={props.plan}
        marketplace={props.marketplace}
        locale={intl.locale}
        newPlan={props.newPriceUpdatePlan}
      >
        <div className={style.heading}>
          <PHeading size={'large'}>
            <TariffName />
          </PHeading>
          <PText size={'medium'}>
            <BaseFee />
          </PText>
          <PText size={'x-small'} color={'contrast-medium'}>
            <InclusivePeriod v2PremiumPlan={props.v2PremiumPlan} />
          </PText>
        </div>
        <div className={disclaimer.style}>
          <NorthAmericaOnly>
            <div>
              <Membership
                isNarBasicPremium={
                  props.plan.variant === PlanVariant.V2_PREMIUM_INCLUSIVE
                }
              />
            </div>
          </NorthAmericaOnly>
          <EuropeOnly>
            <Charging
              style={{
                simpleSegmentRoot: style.simpleSegmentRoot,
              }}
              messages={messages}
              components={{
                simpleSegmentSubText: {
                  size: 'x-small',
                },
                simpleSegmentSubTitle: {
                  size: 'x-small',
                },
              }}
            />
          </EuropeOnly>
          {disclaimer.component}
        </div>
      </PlanContextProvider>
    </div>
  );
};

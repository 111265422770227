export function isPcsCountry(country: string): boolean {
  return Object.values(PcsCountry).includes(
    country.toUpperCase() as PcsCountry,
  );
}

export enum PcsCountry {
  AT = 'AT',
  BE = 'BE',
  CA = 'CA',
  CH = 'CH',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GB = 'GB',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IT = 'IT',
  LI = 'LI',
  LT = 'LT',
  LV = 'LV',
  LU = 'LU',
  NL = 'NL',
  NO = 'NO',
  PL = 'PL',
  PT = 'PT',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
  US = 'US',
}

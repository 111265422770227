import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Reactivate the subscription',
    id: 'reactivate.title',
  },
  subtitle: {
    defaultMessage:
      'You are about to reactivate the subscription you have been using previously under the same conditions. The change will take effect within 24 hours.',
    id: 'reactivate.subtitle',
  },
  reactivateButtonLabel: {
    defaultMessage: 'Reactivate subscription',
    id: 'reactivate.reactivateButtonLabel',
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'reactivate.cancelButtonLabel',
  },
  errorTitle: {
    defaultMessage: 'Reactivation failed',
    id: 'reactivate.errorTitle',
  },
  errorSubtitle: {
    defaultMessage:
      "We couldn't reactivate your subscription. Please try again or contact our {customerSupport}.",
    id: 'reactivate.errorSubtitle',
  },
  customerSupport: {
    defaultMessage: 'support team',
    id: 'reactivate.customerSupport',
  },
  successMessage: {
    defaultMessage: 'Subscription reactivated successfully.',
    id: 'reactivate.successMessage',
  },
});

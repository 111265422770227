import { FC, useContext, useMemo } from 'react';
import {
  PCheckboxWrapper,
  PHeading,
  PLinkPure,
  PTag,
  PText,
} from '@porsche-design-system/components-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './checkout.messages';
import { AddressPreview } from './components/AddressPreview';
import { AddressWidget } from './components/AddressWidget';
import { BusinessVerification } from './components/BusinessVerification';
import { CheckoutStep } from './components/CheckoutStep';
import { OrderSummary } from './components/OrderSummary';
import { PaymentPreview } from './components/PaymentPreview';
import { PaymentWidget } from './components/PaymentWidget';
import { useCheckout, ActiveStep } from './useCheckout';
import style from './checkout.module.scss';
import { Step, Stepper } from 'components/stepper/Stepper';
import { PlanVariant } from 'pages/tariff/select/SelectPage';
import { vehicleContext } from 'components/context/vehicle/VehicleContextProvider';
import { TermsAndConditions } from 'components/tac/TermsAndConditions';
import { Option, usePlans } from 'hooks/usePlans';
import { useRoutes } from 'hooks/useRoutes';
import { Routes } from 'router/routes';

interface CheckoutProps {
  authorizationToken: string;
  country: string;
  locale: string;
  widgetApiKey: string;
  planVariant: PlanVariant;
  vin: string;
}

export const Checkout: FC<CheckoutProps> = ({
  authorizationToken,
  country,
  locale,
  planVariant,
  vin,
  widgetApiKey,
}) => {
  const { vehicleName, inclusivePeriodUntil } = useContext(vehicleContext);
  const { normalizedPlans } = usePlans();
  const routes = useRoutes();

  const intl = useIntl();

  const selectedPlan = useMemo(
    () => normalizedPlans?.find((plan) => plan.type === planVariant),
    [normalizedPlans, planVariant],
  );

  const selectedPlanTaxPercentage = Array.isArray(selectedPlan?.options)
    ? selectedPlan.options.find(
        (singleOption: Option) =>
          singleOption.country === country && singleOption.pricingModel.taxRate,
      )?.pricingModel.taxRate
    : undefined;

  const hasFollowupPlan = useMemo(
    () => !!selectedPlan?.exclusiveFollowUpPlanId,
    [selectedPlan],
  );
  const isInclusivePlan = useMemo(
    () =>
      selectedPlan?.variant &&
      [
        PlanVariant.V1_PREMIUM_INCLUSIVE,
        PlanVariant.V2_PREMIUM_INCLUSIVE,
      ].includes(selectedPlan.variant),
    [selectedPlan],
  );
  const {
    activeStep,
    checkoutDetails,
    completedSteps,
    getStepStatus,
    handleAddressChange,
    handleAddressEdit,
    handleBusinessChange,
    handleBusinessVerificationCompletion,
    handleDeliveryAddressCompletion,
    handleInvoiceAddressCompletion,
    handlePaymentChange,
    handlePaymentCompletion,
    handleStepEdit,
    handleTermsAndConditionCompletion,
    hasFailedBusinessVerification,
    isAddressAllowed,
    isEditingAddress,
    isLoading,
    isPayPalSelected,
    previewDetails,
    requiresBusinessVerification,
    requiresDeliveryAddress,
    stepsValue,
    handleApplePayToken,
    isInitialApplePaySelected,
    applePayToken,
    toggleHasAcceptedTermsAndConditions,
    toggleHasAcceptedRevocationPeriod,
    toggleIsDeliveryTheSame,
  } = useCheckout({
    authorizationToken,
    planVariant,
    vin,
    widgetApiKey,
    country,
    locale,
    currencyCode: selectedPlan?.context.currencyCode,
  });

  return (
    <>
      <Stepper currentStep={Step.Activation} />
      <div className={style.root} data-testid={'checkout'}>
        <div className={style.checkoutSteps}>
          <PLinkPure
            href={routes(Routes.TariffSelect)}
            icon="arrow-left"
            className={style.backLink}
          >
            <FormattedMessage {...messages.backButtonLabel} />
          </PLinkPure>
          <PHeading size="x-large" className={style.title}>
            <FormattedMessage {...messages.title} />
          </PHeading>

          <div className={style.orderSummaryMobile}>
            <OrderSummary
              vehicleName={vehicleName!}
              tariffExpiration={inclusivePeriodUntil}
              vin={vin}
              planName={selectedPlan?.context.getTariffName() || ''}
              planPrice={selectedPlan?.context.baseFee || ''}
              hasFollowupPlan={hasFollowupPlan}
              taxPercentage={selectedPlanTaxPercentage}
              isInclusivePlan={isInclusivePlan}
            />
          </div>

          <CheckoutStep
            isLoading={isLoading}
            onEdit={handleStepEdit}
            onSubmit={handleInvoiceAddressCompletion}
            hideSubmit={isEditingAddress}
            status={getStepStatus(ActiveStep.InvoiceAddress)}
            stepId={ActiveStep.InvoiceAddress}
            stepNumber={ActiveStep.InvoiceAddress}
            submitLabel={intl.formatMessage(
              messages.billingAddressActionButtonLabel,
            )}
            title={intl.formatMessage(messages.billingAddressSectionTitle)}
            hasError={!isAddressAllowed}
            errorMessage={intl.formatMessage(messages.addressNotAllowedError)}
          >
            {activeStep === ActiveStep.InvoiceAddress ? (
              <AddressWidget
                authorizationToken={authorizationToken}
                defaultAddressId={checkoutDetails.invoiceAddressId}
                onChange={handleAddressChange}
                onAddressEdit={handleAddressEdit}
              />
            ) : (
              <AddressPreview
                address={previewDetails[ActiveStep.InvoiceAddress]}
              />
            )}
          </CheckoutStep>

          {requiresBusinessVerification ? (
            <CheckoutStep
              isLoading={isLoading}
              onEdit={
                completedSteps.has(ActiveStep.BusinessVerification)
                  ? undefined
                  : handleStepEdit
              }
              onSubmit={handleBusinessVerificationCompletion}
              status={getStepStatus(ActiveStep.BusinessVerification)}
              stepId={ActiveStep.BusinessVerification}
              stepNumber={ActiveStep.BusinessVerification}
              submitLabel={intl.formatMessage(
                messages.bussinessVerificationActionButtonLabel,
              )}
              title={intl.formatMessage(
                messages.bussinessVerificationSectionTitle,
              )}
              isSubmitDisabled={
                !stepsValue[ActiveStep.BusinessVerification].legalName ||
                !stepsValue[ActiveStep.BusinessVerification].vat
              }
            >
              {activeStep === ActiveStep.BusinessVerification ? (
                <BusinessVerification
                  legalName={
                    stepsValue[ActiveStep.BusinessVerification].legalName
                  }
                  vat={stepsValue[ActiveStep.BusinessVerification].vat}
                  onChange={handleBusinessChange}
                  hasError={hasFailedBusinessVerification}
                />
              ) : (
                <PTag
                  className={style.pushed}
                  color="notification-success-soft"
                >
                  <FormattedMessage {...messages.verified} />
                </PTag>
              )}
            </CheckoutStep>
          ) : null}

          {requiresDeliveryAddress ? (
            <CheckoutStep
              isEditDisabled={activeStep === ActiveStep.BusinessVerification}
              isLoading={isLoading}
              onEdit={handleStepEdit}
              onSubmit={handleDeliveryAddressCompletion}
              hideSubmit={isEditingAddress}
              status={getStepStatus(ActiveStep.DeliveryAddress)}
              stepId={ActiveStep.DeliveryAddress}
              stepNumber={
                ActiveStep.DeliveryAddress -
                (requiresBusinessVerification ? 0 : 1)
              }
              submitLabel={intl.formatMessage(
                messages.deliveryAddressActionButtonLabel,
              )}
              title={intl.formatMessage(messages.deliveryAddressSectionTitle)}
            >
              {activeStep === ActiveStep.DeliveryAddress ? (
                <>
                  <PCheckboxWrapper
                    className={style.pushed}
                    label={intl.formatMessage(
                      messages.deliveryAddressCheckboxLabel,
                    )}
                  >
                    <input
                      type="checkbox"
                      name="some-name"
                      checked={previewDetails.isDeliveryTheSame}
                      onChange={toggleIsDeliveryTheSame}
                    />
                  </PCheckboxWrapper>
                  {!previewDetails.isDeliveryTheSame ? (
                    <AddressWidget
                      authorizationToken={authorizationToken}
                      defaultAddressId={checkoutDetails.deliveryAddressId}
                      onChange={handleAddressChange}
                      onAddressEdit={handleAddressEdit}
                    />
                  ) : null}
                </>
              ) : previewDetails.isDeliveryTheSame ? (
                <PText
                  color="contrast-medium"
                  size="small"
                  className={style.deliveryAddressText}
                >
                  {intl.formatMessage(messages.deliveryAddressCheckboxLabel)}
                </PText>
              ) : (
                <AddressPreview
                  address={previewDetails[ActiveStep.DeliveryAddress]}
                />
              )}
            </CheckoutStep>
          ) : null}

          <CheckoutStep
            isEditDisabled={activeStep === ActiveStep.BusinessVerification}
            isLoading={isLoading}
            onEdit={handleStepEdit}
            onSubmit={
              stepsValue[ActiveStep.Payment] &&
              Object.values(stepsValue[ActiveStep.Payment]).length
                ? handlePaymentCompletion
                : undefined
            }
            status={getStepStatus(ActiveStep.Payment)}
            stepId={ActiveStep.Payment}
            stepNumber={
              ActiveStep.Payment -
              (requiresBusinessVerification ? 0 : 1) -
              (requiresDeliveryAddress ? 0 : 1)
            }
            submitLabel={intl.formatMessage(messages.paymentActionButtonLabel)}
            title={intl.formatMessage(messages.paymentSectionTitle)}
            isSubmitDisabled={isInitialApplePaySelected && !applePayToken}
          >
            {activeStep === ActiveStep.Payment ? (
              <PaymentWidget
                apiKey={widgetApiKey}
                authorizationToken={authorizationToken}
                marketplace={country}
                locale={locale}
                onChange={handlePaymentChange}
                defaultPaymentMethodId={checkoutDetails.paymentRef}
                redirectUrl={window.location.href}
                isPayPalSelected={isPayPalSelected}
                currency={selectedPlan?.context.currencyCode}
                handleApplePayTokenSave={handleApplePayToken}
              />
            ) : (
              <PaymentPreview payment={previewDetails[ActiveStep.Payment]} />
            )}
          </CheckoutStep>

          <CheckoutStep
            isLoading={isLoading}
            isSubmitDisabled={
              !previewDetails.hasAcceptedTermsAndConditions ||
              !previewDetails.hasAcceptedRevocationPeriod
            }
            onSubmit={handleTermsAndConditionCompletion}
            status={getStepStatus(ActiveStep.TermsAndConditions)}
            stepId={ActiveStep.TermsAndConditions}
            stepNumber={
              ActiveStep.TermsAndConditions -
              (requiresBusinessVerification ? 0 : 1) -
              (requiresDeliveryAddress ? 0 : 1)
            }
            submitLabel={intl.formatMessage(
              messages.termsAndConditionsActionButtonLabel,
            )}
            title={intl.formatMessage(messages.termsAndConditionsSectionTitle)}
          >
            <TermsAndConditions
              checked={previewDetails.hasAcceptedTermsAndConditions}
              revocationPeriodChecked={
                previewDetails.hasAcceptedRevocationPeriod
              }
              onChange={toggleHasAcceptedTermsAndConditions}
              onRevocationPeriodChange={toggleHasAcceptedRevocationPeriod}
              className={style.pushed}
            />
          </CheckoutStep>
        </div>
        <div className={style.orderSummaryDesktop}>
          <OrderSummary
            vehicleName={vehicleName!}
            tariffExpiration={inclusivePeriodUntil}
            vin={vin}
            planName={selectedPlan?.context.getTariffName() || ''}
            planPrice={selectedPlan?.context.baseFee || ''}
            hasFollowupPlan={hasFollowupPlan}
            taxPercentage={selectedPlanTaxPercentage}
            isInclusivePlan={isInclusivePlan}
          />
        </div>
      </div>
    </>
  );
};

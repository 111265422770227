import React, { ReactNode, useEffect, useState } from 'react';
import { dummyPlanContext, planContext, PlanContext } from './PlanContext';
import { observer } from 'mobx-react-lite';
import { RawPlan } from './planContext.types';

interface PlanContextProviderProps {
  children?: ReactNode;
  plan: RawPlan | null;
  marketplace: string;
  locale: string;
  newPlan?: RawPlan | null;
}

/**
 * Creates and provides {@link PlanContext} <br/>
 * If props.plan is null a dummy will be created
 */
export const PlanContextProvider: React.FunctionComponent<PlanContextProviderProps> =
  observer((props) => {
    const [context, setContext] = useState<PlanContext>(dummyPlanContext);

    useEffect(() => {
      if (!props.plan) {
        setContext(dummyPlanContext);
        return;
      }
      setContext(
        new PlanContext({
          dummy: false,
          marketplace: props.marketplace,
          plan: props.plan,
          locale: props.locale,
          newPlan: props.newPlan,
        }),
      );
    }, [props.plan, props.marketplace, props.locale, props.newPlan]);

    return (
      <planContext.Provider value={context}>
        {props.children}
      </planContext.Provider>
    );
  });

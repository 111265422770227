import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Payment details',
    id: 'suspendedFlyout.overview.header.title',
  },
  description: {
    defaultMessage:
      'To reinstate your subscription, please complete the payment using one of the methods below.',
    id: 'suspendedFlyout.overview.header.description',
  },
});

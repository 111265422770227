import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VehicleContext } from './VehicleContext';
import {
  Authentication,
  useAuthentication,
} from '../../../hooks/useAuthentication/useAuthentication';
import { Ownership } from '../../misc/ownership/Ownership';

interface MyChargingContextProps {
  children?: ReactNode;
}

const dummyContext = new VehicleContext({
  dummy: true,
  vin: '',
  marketplace: '',
  auth: {} as Authentication,
});
export const vehicleContext = createContext<VehicleContext>(dummyContext);

export const VehicleContextProvider: React.FunctionComponent<
  MyChargingContextProps
> = (props) => {
  const params = useParams<{ vin: string; marketplace: string }>();
  const [context, setContext] = useState<VehicleContext>(dummyContext);
  const auth = useAuthentication();

  /**
   * The vehicle context is entity bound to the vin <br/>
   * Reset the context if the vin changes
   */
  useEffect(() => {
    if (
      !params.vin ||
      !params.marketplace ||
      !auth.apiKey ||
      !auth.ciamId ||
      !auth.token
    ) {
      return;
    }
    setContext(
      new VehicleContext({
        vin: params.vin,
        marketplace: params.marketplace.toUpperCase(),
        auth: auth,
        dummy: false,
      }),
    );
  }, [params.vin, params.marketplace, auth.apiKey, auth.ciamId, auth.token]);

  return (
    <vehicleContext.Provider value={context}>
      <Ownership />
      {props.children}
    </vehicleContext.Provider>
  );
};

import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    defaultMessage: 'Tariff',
    id: 'flyout.overview.tariff.title',
  },
  action: {
    defaultMessage: 'Manage',
    id: 'flyout.overview.tariff.action',
  },
  terminationProgress: {
    defaultMessage: 'Subscription termination in progress',
    id: 'flyout.overview.tariff.terminationProgress',
  },
  suspendedUpdate: {
    defaultMessage: 'Update disabled until payment is made',
    id: 'flyout.overview.tariff.suspendedUpdate',
  },
});

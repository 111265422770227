import { init, isInitialized } from '@fullstory/browser';
import { useCookieConsent } from 'hooks/useCookieConsent';
import { FC } from 'react';

export const FullStory: FC = () => {
  const { cookieConsentLoaded, hasCookieConsent } =
    useCookieConsent('Fullstory');

  if (
    process.env.REACT_APP_IS_FULL_STORY_ENABLED &&
    cookieConsentLoaded &&
    hasCookieConsent &&
    !isInitialized()
  ) {
    init({ orgId: process.env.REACT_APP_FULL_STORY_ORGANIZATION_ID! });
  }

  return null;
};

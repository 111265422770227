import {
  PDivider,
  PHeading,
  PText,
} from '@porsche-design-system/components-react';
import { planContext } from 'components/context/plan/PlanContext';
import { RawPlan } from 'components/context/plan/planContext.types';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { TDefaultParams } from 'router/routes';
import style from './style.module.scss';
import { messages } from './InvitationOrderSummary.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { TariffName } from 'components/tariff/components/name/TariffName';
import { observer } from 'mobx-react-lite';
import { NorthAmericaOnly } from '../../../../components/context/plan/discriminators/region/NorthAmericaOnly';
import { EuropeOnly } from '../../../../components/context/plan/discriminators/region/EuropeOnly';
// "Temporarily" use disclaimer messages, as currently we can't translate new text before release
import { messages as disclaimerMessages } from '../../../../components/tariff/components/disclaimer/disclaimer.messages';
import { TaxPercentage } from 'components/taxPercentage/TaxPercentage';
import { PlanVariant } from 'hooks/usePlans';

interface IProps {
  selectedPlan?: RawPlan;
}

const InvitationOrderSummary: React.FC<IProps> = observer(
  ({ selectedPlan }) => {
    const { vin } = useParams<TDefaultParams>();
    const intl = useIntl();
    const context = useContext(planContext);
    const selectedPlanTax = selectedPlan?.options[context.marketplace]?.find(
      (singleOption) => singleOption.pricingModel.taxRate,
    )?.pricingModel.taxRate;
    const isInclusivePlan =
      selectedPlan &&
      [
        PlanVariant.V1_PREMIUM_INCLUSIVE,
        PlanVariant.V2_PREMIUM_INCLUSIVE,
      ].includes(selectedPlan?.variant);

    const baseFee = context?.baseFee || 0;
    const numberBaseFee = Number(baseFee.toString().replace(/[^0-9.-]+/g, ''));
    return (
      <>
        <PHeading size="x-large" className={`${style.subTitle} ${style.pt4}`}>
          <FormattedMessage {...messages.orderSummaryTitle} />
        </PHeading>
        {numberBaseFee > 0 ? (
          <>
            <PText className={style.pt3} size="large" weight="semi-bold">
              <FormattedMessage {...messages.orderSummaryHeadingMonthly} />
            </PText>
            <PText size="small" weight="regular">
              <FormattedMessage {...messages.orderSummaryDisclaimerMonthly} />
            </PText>
          </>
        ) : (
          <>
            <PText className={style.pt3} size="large" weight="semi-bold">
              <FormattedMessage {...messages.orderSummaryHeading} />
            </PText>
            <PText size="small" weight="regular">
              <FormattedMessage {...messages.orderSummaryDisclaimer} />
            </PText>
          </>
        )}
        <PText size="x-small" className={style.pt3} weight="bold">
          {intl.formatMessage(messages.vin, {
            vin,
          })}
        </PText>
        <div className={style.orderLine}>
          <PText size="x-small" weight="regular">
            {selectedPlan && <TariffName />}
          </PText>
          <PText size="x-small" weight="regular">
            {baseFee}
          </PText>
        </div>
        <PDivider color="contrast-low" className={style.pt3} />
        <div className={`${style.orderLine} ${style.pt3}`}>
          <PText size="medium" weight="bold">
            <FormattedMessage {...messages.totalToPay} />
          </PText>
          <div>
            <PText align="right" size="medium" weight="bold">
              {baseFee}
            </PText>
            <PText
              color="neutral-contrast-high"
              size="x-small"
              className={style.pt1}
              weight="regular"
            >
              <NorthAmericaOnly>
                <FormattedMessage {...disclaimerMessages.excluded} />
              </NorthAmericaOnly>
              <EuropeOnly>
                {selectedPlanTax && !isInclusivePlan ? (
                  <TaxPercentage taxPercentage={selectedPlanTax} />
                ) : (
                  <FormattedMessage {...disclaimerMessages.included} />
                )}
              </EuropeOnly>
            </PText>
          </div>
        </div>
      </>
    );
  },
);

export default InvitationOrderSummary;

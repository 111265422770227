import { ReactNode } from 'react';

export enum CustomerType {
  B2C = 'B2C',
  B2B = 'B2B',
  AFFILIATED = 'AFFILIATED',
}

export enum Region {
  EU = 'EU',
  CA = 'CA',
  US = 'US',
}

export enum PlanVariant {
  V1_PREMIUM_INCLUSIVE = 'V1_PREMIUM_INCLUSIVE',
  V2_PREMIUM_INCLUSIVE = 'V2_PREMIUM_INCLUSIVE',
  V2_PREMIUM = 'V2_PREMIUM',
  V2_BASIC = 'V2_BASIC',
}

export enum FeeType {
  // shared by all
  BASE = 'BASE',
  ENERGY = 'ENERGY',

  // EU
  BLOCKING = 'BLOCKING',

  // NAR
  FREE_ENERGY = 'FREE_ENERGY',
  OVERAGE = 'OVERAGE', // fee applied during charging after free charging time is over
  FREE_CHARGING_TIME = 'FREE_CHARGING_TIME', // free fee applied during charging
  IDLE = 'IDLE', // idle fee applied after charging ended (grace period already deducted)
}

export interface RawPlan {
  id: string;
  name: string;
  variant: PlanVariant;
  region: Region;
  options: {
    [country: string]: Option[];
  };
  pcsVersion: string;
}

export enum quantityType {
  PC = 'PC',
  KWH = 'KWH',
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface Price {
  netAmount: {
    decimal: number;
    currency: string;
    cents: number;
  };
  grossAmount: {
    decimal: number;
    currency: string;
    cents: number;
  };
}

export interface BasePricing {
  feeType: FeeType.BASE;
  pricingModel: {
    type: PricingType.Recurring;
    taxRate: number;
    price: Price;
  };
}

export interface UsagePricing {
  feeType: FeeType;
  chargePointClass: ChargePointClass | null;
  pricingModel: {
    type: PricingType.UsageBased;
    quantityType: quantityType;
    taxRate: number;
    priceTiers: { price: Price; quantity: number }[];
  };
}

export type Option = UsagePricing | BasePricing;

export enum PricingType {
  Recurring = 'recurring',
  UsageBased = 'usageBased',
}

export enum ChargePointClass {
  // 2.0
  AC = 'AC',
  DC = 'DC',
  LOUNGE = 'LOUNGE',
  PREFERRED = 'PREFERRED',
  // 1.0
  AC_3_KW = 'AC_3_KW',
  AC_11_KW = 'AC_11_KW',
  DC_20_KW = 'DC_20_KW',
  AC_22_KW = 'AC_22_KW',
  DC_50_KW = 'DC_50_KW',
  AC_43_KW = 'AC_43_KW',
  DC_150_KW = 'DC_150_KW',
  HPC_350_KW = 'HPC_350_KW',
  IONITY_350_KW = 'IONITY_350_KW',
  UNKNOWN = 'UNKNOWN',
}

/**
 * Blocking
 */
export enum BlockingType {
  Instant,
  ByTime,
  ByPower,
}

interface BlockingInstant {
  type: BlockingType.Instant;
  price: string;
}

interface BlockingByTime {
  type: BlockingType.ByTime;
  price: string;
  time: number;
}

interface BlockingByPower {
  type: BlockingType.ByPower;
  price: string;
  kw: number;
}

export type BlockingPrefixed = (
  | BlockingInstant
  | BlockingByTime
  | BlockingByPower
) & {
  prefix: ReactNode;
};

export type Blocking =
  | BlockingInstant
  | BlockingByTime
  | BlockingByPower
  | BlockingPrefixed;
